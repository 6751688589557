import * as config from '../config';

const API_SERVER = config.API_SERVER;

class MusicInfo {
  constructor(id, title, videoUrl, thumbnail, views, owner, duration) {
    this.id = id;
    this.title = title;
    this.videoUrl = videoUrl;
    this.thumbnail = thumbnail;
    this.views = views;
    this.owner = owner;
    this.duration = duration;

    //스트림을 쓸때 
    //this.src = `${API_SERVER}/api/stream/${id}`;
    this.src = `${API_SERVER}/api/video/${id}`;
    this.downloadUrl = `${API_SERVER}/api/video/${id}`;
  }
}

export default MusicInfo;