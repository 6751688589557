import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import * as musicService from '../service/MusicService';

function SearchBar({ searchText, setSearchText, onsubmit }) {
  const [items, setItmes] = useState([]);

  useEffect(() => {
    refreshAutoComplete();
  }, [searchText])

  const refreshAutoComplete = async () => {
    try {
      const res = await musicService.searchRelated(searchText);
      const relatedItems = res.map((x, i) => {
        return {
          id: i,
          name: x,
          score: 1
        }
      });
      setItmes(relatedItems);
    } catch (err) {
      alert("서버 에러가 발생했습니다\n" + err);
      console.error(err);
    }
  }

  const handleOnSearch = (string, results) => {
    setSearchText(string);
  }
  const handleOnSelect = (item) => {
    console.log(item.name);
    setSearchText(item.name);
    onsubmit(item.name);
  }
  const handleOnClear = () => {
    setSearchText("");
  }
  const fuseOptions = {
    shouldSort: false,
    includeScore: true,
    keys: ['name']
  }

  return (
    <ReactSearchAutocomplete
      items={items}
      inputDebounce={0}
      onClear={handleOnClear}
      onSearch={handleOnSearch}
      onSelect={handleOnSelect}
      fuseOptions={fuseOptions}
      autoFocus
    />
  )
}

export default SearchBar;