import React, { useState, useEffect } from 'react';
import { Slider, RangeSlider } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

function MusicPlayer({ currentAudio, currentMusicInfo, isPaused, pauseMusic, playMusic, restartMusic }) {
  const [progress, setProgress] = useState(0.0);
  useEffect(() => {
    const ticks = setInterval(() => {
      setProgress(currentAudio.currentTime)
    }, 100);
    return () => clearInterval(ticks);
  }, []);

  const onChange = (value, e) => {
    currentAudio.currentTime = value;
    setProgress(value);
  }
  return (
    <footer className="footer navbar-fixed-bottom"
      style={{ zIndex: 1 }}>
      {currentAudio.src ? (
        <div className="container-fluid">

          <Slider
            progress
            defaultValue={0}
            max={currentAudio.duration}
            value={progress}
            onChange={onChange}
          />

          <div className="row">
            <div className="itemDesc col-sm-12">
              <p className="title text-line">{currentMusicInfo.title}</p>
            </div>
            <ul className="list-inline">
              {isPaused
                ? <li
                  className="btn"
                  onClick={() => playMusic(currentMusicInfo)}>
                  <span className="glyphicon glyphicon-play" aria-hidden="true"></span>
                </li>
                : <li
                  className="btn"
                  onClick={pauseMusic}>
                  <span className="glyphicon glyphicon-pause" aria-hidden="true"></span>
                </li>
              }
              <li className="btn"
                onClick={restartMusic}>
                <span className="glyphicon glyphicon-repeat" aria-hidden="true"></span>
              </li>
            </ul>
          </div>

        </div >
      ) : (
        <></>
      )}
    </footer >
  );
};

export default MusicPlayer;