import axios from 'axios';
import * as config from '../config';
import MusicInfo from '../model/MusicInfo';

const API_SERVER = config.API_SERVER;

export const searchMusic = (searchText, start = 0, count = 20) => {
  return new Promise((resolve, reject) => {
    if (searchText === "") {
      resolve([]);
      return;
    }

    axios.get(`${API_SERVER}/api/search/${encodeURI(searchText)}/${start}/${count}`)
      .then(res => {
        const videos = res.data.map(video => {
          const id = video.id;
          const title = video.title;
          const videoUrl = video.videoUrl;
          const thumbnail = video.thumbnail;
          const views = video.views;
          const owner = video.owner;
          const duration = video.duration;

          return new MusicInfo(id,
            title,
            videoUrl,
            thumbnail,
            views,
            owner,
            duration);
        });

        resolve(videos);
      })
      .catch(err => {
        alert("서버 에러가 발생했습니다\n" + err);
        reject(err);
      });
  });
};

export const searchRelated = (searchText) => {
  return new Promise((resolve, reject) => {
    if (searchText === "") {
      resolve([]);
      return;
    }

    axios.get(`${API_SERVER}/api/related/${encodeURI(searchText)}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};

export const getGenieChart = (searchText) => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_SERVER}/api/genie`)
      .then(res => resolve(res.data))
      .catch(err => {
        alert("서버 에러가 발생했습니다\n" + err);
        reject(err);
      });
  });
};

export const downloadMusic = (music) => {
  window.open(music.downloadUrl);
};