import React, { useState, useEffect } from 'react';
import * as musicService from '../service/MusicService';
import "./Mainpage.css"

function MainPage() {
  useEffect(() => {
    refreshChart();
  }, [])
  const [baseDate, setBaseDate] = useState("")
  const [chart, setChart] = useState([])
  const refreshChart = async () => {
    try {
      const res = await musicService.getGenieChart();
      setBaseDate(res.date + " " + res.time)
      setChart(res.musicList)
    } catch (err) {
      console.error(err);
    }
  }
  const onClick = (query) => {
    window.open(window.location.origin + "/index.html?search=" + encodeURI(query))
  }
  return (
    <>
      <h3 className="text-center">Welcome to FreeTube</h3>
      <h5 className="text-center">{"지니 실시간 차트 - " + baseDate}</h5>
      {chart.map((item, idx) => {
        return (
          <div className="container" key={idx} onClick={() => onClick(item.title + " " + item.artist)}>
            <div className="chartContainer">
              <div className="ranking">
                {idx + 1}
              </div>
              <div className="coverImage">
                <img className="" src={item.coverImage} alt="" />
              </div>
              <div className="desc">
                <p className="musicTitle">{item.title}</p>
                <p>{item.artist}</p>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MainPage;
