import React, { useState } from 'react';
import * as musicService from '../service/MusicService';

function MusicView({ music, playMusic, downloadMusic }) {

  return (
    <div className="item container" key={music.id}>
      <a href={music.videoUrl} className="thumbnail col-sm-5 col-md-4" target="_blank">
        <img src={music.thumbnail} alt="no image" />
        <p>{music.duration}</p>
      </a>

      <div className="itemDesc col-sm-7 col-md-8">
        <p className="title text-line">{music.title}</p>
        <p className="views">조회수 : {music.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        <p className="author">{music.owner}</p>

        <ul className="list-inline">
          <li className="btn"
            onClick={() => playMusic(music)}>
            <span className="glyphicon glyphicon-play-circle" aria-hidden="true"></span>
          </li>
          <li className="btn"
            onClick={() => downloadMusic(music)}>
            <span className="glyphicon glyphicon-download" aria-hidden="true"></span>
          </li>
        </ul>

      </div>
    </div>
  )
}

export default MusicView;