import React, { useState, useEffect, useRef } from 'react';
import * as musicService from '../service/MusicService';
import MusicView from './MusicView';
import MusicPlayer from './MusicPlayer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'rsuite';
import url from 'url';
import querystring from 'querystring';
import "./App.css"
import SearchBar from './SearchBar';
import MainPage from './MainPage';

function App() {
  const [searchText, setSearchText] = useState("");
  const [pageSearchText, setPageSearchText] = useState("");
  const [musics, setMusics] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(new Audio());
  const [currentMusicInfo, setCurrentMusicInfo] = useState(null);
  const [isPaused, setIsPaused] = useState(true);
  const retryCount = useRef(0);
  const maxRetry = 3;
  const retryInterval = 5000;

  useEffect(() => {
    currentAudio.addEventListener('ended', () => {
      setIsPaused(true);
    });
    currentAudio.addEventListener('pause', () => {
      setIsPaused(true);
    })
    currentAudio.addEventListener('playing', () => {
      setIsPaused(false);
    })
    currentAudio.addEventListener('error', (err) => {
        console.error(`Error playing audio ${err}`);

        if (retryCount.current > maxRetry) {
            alert('노래 재생에 실패했습니다');
        } else {
            setTimeout(() => {
                retryCount.current++;
                currentAudio.load();
                currentAudio.play();
            }, retryInterval)
        }
    })

    const query = querystring.parse(url.parse(window.location.href).query);
    if (query['search']) {
      setSearchText(query['search']);
      search(decodeURI(query['search']));
    }
  }, [])
  const onSubmit = (text) => {
    window.history.replaceState(null, null, `?search=${encodeURI(text ?? searchText)}`);
    search(text ?? searchText);
  };
  const search = async (text) => {
    setSearchText(text);
    if (text.trim()) {
      try {
        setPageSearchText(text);
        const musics = await musicService.searchMusic(text);
        setMusics(musics);
        window.scrollTo(0, 0);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if ("activeElement" in document)
        document.activeElement.blur();

      onSubmit();
    }
  };
  const playMusic = (music) => {
    retryCount.current = 0;
    if (currentMusicInfo == null || currentMusicInfo.id !== music.id) {
      setCurrentMusicInfo(music);
      currentAudio.src = music.src;
      currentAudio.load();
    }
    currentAudio.play();
  };
  const pauseMusic = () => {
    currentAudio.pause();
  };
  const restartMusic = () => {
    currentAudio.currentTime = 0;
    currentAudio.play();
  }
  const downloadMusic = (music) => {
    if (window.confirm(`${music.title}.mp3\n다운로드 받으시겠습니까?`)) {
      musicService.downloadMusic(music);
    }
  }
  const fetchMusics = async () => {
    try {
      const additionalMusics = (await musicService.searchMusic(pageSearchText, musics.length, 20))
        .filter(x => musics.map(e => e.id).indexOf(x.id) === -1);

      setMusics(musics.concat(additionalMusics));
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <div className="App">

      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="input-group"
            onKeyPress={onKeyPress}>
            <SearchBar
              searchText={searchText}
              setSearchText={setSearchText}
              onsubmit={onSubmit}
            />
          </div>
        </div>
      </nav>

      {pageSearchText.length === 0 && <MainPage />}

      <InfiniteScroll
        dataLength={musics.length}
        next={fetchMusics}
        hasMore={true}
      >
        <section className="container-fluid">
          {musics.map(music =>
            <MusicView
              key={music.id}
              music={music}
              playMusic={playMusic}
              downloadMusic={downloadMusic} />
          )}
          {pageSearchText.length > 0 &&
            <div className="text-center">
              <Loader size="md" />
            </div>
          }
        </section>
      </InfiniteScroll>

      {currentAudio.src && <div className="empty-space" />}

      <MusicPlayer
        currentAudio={currentAudio}
        currentMusicInfo={currentMusicInfo}
        isPaused={isPaused}
        pauseMusic={pauseMusic}
        playMusic={playMusic}
        restartMusic={restartMusic}
      />

    </div >
  );
};

export default App;
